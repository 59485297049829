import Routers from './Routers';

function App() {
	return (
		<div>
			<Routers />
		</div>
	);
}

export default App;
